<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="ds-row")
    td.ds-index.pl-1
      span.pr-1 {{ positionInList }}
    td.ds-name
      span {{ item.name }}
    td.ds-slug
      span {{ item.slug }}
    td.ds-number
      span {{ item.number }}
    td.ds-candidates-count
      span {{ item.count }}
    td.ds-amount-calc
      span €{{ item.amount }}
    td.ds-amount-unpaid
      span {{ item.count_unpaid }}
    td.ds-count-unpaid
      span €{{ item.amount_unpaid }}
    td.ds-actions
      .d-flex
        driving-school-crud(:item="item" editable @needToUpdate="$emit('needToUpdate')")
          template(v-slot:activator="schoolCrudEdit")
            app-button(icon @click.native="schoolCrudEdit.toggle").mr-2
              ico-edit

        driving-school-crud(:item="item" removable @needToUpdate="$emit('needToUpdate')")
          template(v-slot:activator="schoolCrudRemove")
            app-button(icon @click.native="schoolCrudRemove.toggle" :disabled="item.amount === '0.00' ? false : true")
              ico-remove
</template>

<script>
import DrivingSchoolCrud from '../DrivingSchoolCRUD.vue'
import appButton from '@/components/global/actions/BaseButton.vue'
import icoEdit from '@/assets/img/ui/crm/IcoEdit.vue'
import icoRemove from '@/assets/img/ui/crm/IcoRemove.vue'

export default {
  props: {
    isSelected: Boolean,
    expand: Function,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    loading: Boolean,
  },

  computed: {
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size
    },
  },

  components: {
    DrivingSchoolCrud,
    appButton,
    icoEdit,
    icoRemove
  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.ds-row {
  .ds- {
    &id {
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.ds-id {
  padding-left: 4px !important;
}
</style>
